import React, { useEffect, useState } from "react"
import Layout from "../components/Layout"
import SecondaryHeader from "../components/SecondaryHeader"
import Seo from "../components/Seo"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import { Tooltip as ReactTooltip } from "react-tooltip"
import { Helmet } from "react-helmet"
import { GatsbySeo } from "gatsby-plugin-next-seo"

const ServicePage = ({ data }) => {
  const [servicesImages, setServicesImages] = useState([])

  useEffect(() => {
    const servicesImages = data.servicesFlowImgs.edges.map((imgItem, index) => {
      return imgItem.node.fluid
    })

    setServicesImages(servicesImages)
  }, [])

  const serviceData = () => {
    const serviceWithImageData = [
      {
        title: "Crowding",
        description:
          "Crowding is the lack of space for all teeth to fit normally within the jaws, and occurs when teeth are larger than available space, or when there is disharmony in toot vs. jaw size.",
      },
      {
        title: "Spacing",
        description:
          "Spacing (the opposite of crowding) occurs when there is excess space for the teeth in the jaw causing gaps between them, generally occurring when teeth are smaller than available space.",
      },
      {
        title: "Deep Bite",
        description:
          "A Deep Bite (also called Overbite) occurs when the upper front teeth overlap the lower front teeth vertically when biting down, resulting in the lower front teeth being partially or fully covered.",
      },
      {
        title: "Overjet",
        description:
          "Overjet (also referred to as “buck teeth”) refers to a condition where the upper front teeth extend significantly/protrude outward away from the lower front teeth horizontally rather than vertically.",
      },
      {
        title: "Underbite",
        description:
          "An Underbite (the opposite of a Deep Bite) occurs when the lower jaw protrudes/extends beyond the upper jaw, causing the lower front teeth to sit in front of the upper front teeth.",
      },
      {
        title: "Open Bite",
        description:
          "An Open Bite occurs when the upper and lower teeth do not make contact when biting, creating an opening between the biting surfaces of the front teeth and possibly side teeth.",
      },
      {
        title: "Midline Shift",
        description:
          "A Midline Shift occurs when the line between the top teeth does not sit directly over the line between the bottom teeth. It can be obvious or barely noticeable and may affect the back bite.",
      },
    ]

    data.servicesImgs.edges.map((imgItem, index) => {
      serviceWithImageData[index].imgSrc = imgItem.node.fluid
    })

    return serviceWithImageData
  }

  const RenderDownArrowDivider = () => {
    return (
      <div className="divider-arrow">
        <Img fluid={data.downArrow.childImageSharp.fluid} />
      </div>
    )
  }

  const RenderServiceSectionCard = ({ imgSrc, description, title }) => {
    return (
      <div className="section__inner__img-container__service-item flex flex-col gap-4 items-center">
        <Img fluid={imgSrc} alt={imgSrc.src} />
        <p className="font-black">{title}</p>
        <p
          style={{ fontSize: "0.8rem" }}
          className="section__inner__img-container__service-item__description text-center"
        >
          {description}
        </p>
      </div>
    )
  }

  return (
    <Layout>
      <GatsbySeo
        title="description"
          description="AlignerBase Services - Expertise in Clear Aligner Treatment Planning. Reduce costs by 70%, improve predictability, save time with our experienced team. Discover savings today!"
      />
      <Helmet>
        <meta charset="utf-8" />
      </Helmet>
      <Seo title={`Planning and designing services`} />
      <SecondaryHeader title={<>Design Services</>} />
      <div id="service-page">
        <section className="design-section">
          <div className="design-section__img-wrapper">
            <Img fluid={data.mainImg.childImageSharp.fluid} />
          </div>
          <div className="design-section__text">
            <h3>Treatment plans designed by experts</h3>
            <div className="design-section__text__expanded">
              <p>
                Our team of expert orthodontists and technicians have delivered
                over 100,000 aligner cases and is equipped with tools to improve
                treatment predictability, reduce refinement and revision rates,
                and deliver significant savings in time and cost.
              </p>
              <p>
                With prices at $59 or less per plan, save upwards of 70% on
                treatment planning costs with AlignerBase.
              </p>
              <p>
                <Link to="/plan-and-pricing">View Plans and Pricing here.</Link>
              </p>
            </div>

            <div className="design-section__text__offer">
              <Link to={"/doctor-registration"}>
                <button>Sign Up for Free Trial</button>
              </Link>
            </div>
          </div>
        </section>

        {servicesImages.length > 0 && (
          <div className="section__inner service-section steps">
            <div className="divider-large"></div>
            <h3>What you send via the AlignerBase Platform</h3>
            <div className="flex">
              <div className="service-section__container">
                <div className="service-section__container__img-container">
                  <Img fluid={servicesImages[0]} alt={servicesImages[0].src} />
                  <p>Scan of lower and upper jaw</p>
                </div>
              </div>
              <div className="service-section__container">
                <div className="service-section__container__img-container">
                  <Img fluid={servicesImages[1]} alt={servicesImages[1].src} />
                  <p>Patient Rx form (available in the portal)</p>
                </div>
              </div>
              <div className="service-section__container">
                <div className="service-section__container__img-container">
                  <Img fluid={servicesImages[2]} alt={servicesImages[2].src} />
                  <p>Patient photos</p>
                </div>
              </div>
            </div>
            <RenderDownArrowDivider />
            <h4>What you receive before approving plan:</h4>
            <div className="flex">
              <div className="service-section__container">
                <div className="service-section__container__img-container">
                  <Img fluid={servicesImages[3]} alt={servicesImages[3].src} />
                  <p>Treatment plan detailed report</p>
                </div>
              </div>
              <div className="service-section__container">
                <div className="service-section__container__img-container">
                  <Img fluid={servicesImages[4]} alt={servicesImages[4].src} />
                  <p>Treatment plan video</p>
                </div>
              </div>
              <div className="service-section__container">
                <div className="service-section__container__img-container">
                  <Img fluid={servicesImages[5]} alt={servicesImages[5].src} />
                  <p>
                    Treatment plan 3D visualization <br /> (coming soon)
                  </p>
                </div>
              </div>
            </div>
            <RenderDownArrowDivider />
            <h4>What you receive after approving plan:</h4>
            <div className="flex justify-center">
              <div
                className="service-section__container"
                style={{ width: "50%" }}
              >
                <div className="service-section__container__img-container">
                  <Img fluid={servicesImages[6]} alt={servicesImages[6].src} />
                  <p>
                    Editable treatment plan file (e.g., .bsb or 3sz file format)
                  </p>
                </div>
              </div>
              <div
                className="service-section__container"
                style={{ width: "50%" }}
              >
                <div className="service-section__container__img-container">
                  <Img fluid={servicesImages[7]} alt={servicesImages[7].src} />
                  <p>Print ready treatment plan file in STL format</p>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="divider-large" />

        <section className="section-get-more-info">
          <h3>Connect with us to learn more about the AlignerBase Platform.</h3>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              gap: "8px",
              marginTop: "8px",
            }}
          >
            <Link to={"/contact-us"}>
              <button className="">Contact Us</button>
            </Link>
            <Link to={"/doctor-registration"}>
              <button>Sign Up</button>
            </Link>
          </div>
        </section>
        <div className="divider-large"></div>

        <section className="section service-section">
          <div className="section__inner pt-0">
            <h3 className="font-black text-center">
              Types of malocclusion AlignerBase Plans can treat:
            </h3>
            <p className="section__text"></p>
            <div className="section__inner__img-container">
              {[...serviceData()].map((item, index) => (
                <RenderServiceSectionCard
                  key={item.title}
                  title={item.title}
                  description={item.description}
                  imgSrc={item.imgSrc}
                />
              ))}
            </div>
            <div className="py-5" />
            <div className="py-5" />
            <div className="text-xs text-center">
              While clear aligners have the potential to improve all types of
              occlusion, we cannot promise a perfect outcome. To determine if
              clear aligner therapy is suitable for a patient, the treating
              doctor should consider the overall treatment goal, the movements
              required, and the current state of the patient's dentition.
            </div>
          </div>
        </section>

        <section className="section turnaround-section">
          <div className="section__bg-overlay overlay"></div>
          <div className="section__bg-image overlay"></div>
          <div className="section__inner max-width-780">
            <h2 className="section__heading">
              Higher aligner margins begin with affordable treatment plans
            </h2>
            <div className="section__divider divider"></div>
            <p className="section__text">
              Sign up with AlignerBase today and maximize your clear aligner
              potential.
            </p>
            <div className="text-center">
              <Link to={"/doctor-registration"}>
                <button className="turnaround-section__btn">Get Started</button>
              </Link>
            </div>
          </div>
        </section>
      </div>
      <ReactTooltip id="my-tooltip" place="bottom" />
    </Layout>
  )
}

export default ServicePage

export const query = graphql`
  query {
    mainImg: file(relativePath: { eq: "alignerbase-service-img-1.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 700) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    downArrow: file(relativePath: { eq: "down-arrow.png" }) {
      childImageSharp {
        fluid(maxWidth: 150) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    servicesImgs: allImageSharp(
      sort: { fields: [fluid___originalName], order: ASC }
      filter: {
        fluid: { originalName: { regex: "/alignerbase-home-service-img-*/" } }
      }
    ) {
      edges {
        node {
          id
          fluid(maxWidth: 200, maxHeight: 150) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
    servicesFlowImgs: allImageSharp(
      sort: { fields: [fluid___originalName], order: ASC }
      filter: {
        fluid: {
          originalName: { regex: "/alignerbase-service-service-img-*/" }
        }
      }
    ) {
      edges {
        node {
          id
          fluid(maxWidth: 150, maxHeight: 150) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
`
